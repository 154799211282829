/*
 * @Auth: linjituan
 * @Date: 2022-06-01 17:47:48
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-05-29 15:43:47
 */
/*
 * @Auth: linjituan
 * @Date: 2021-11-22 16:46:53
 * @LastEditors: linjituan
 * @LastEditTime: 2022-06-01 17:46:39
 */

const project = {
  homeIntroduction: ['加快传统回收产业数字化转型', '助力品质生活之城绽放新颜值'],
  systemName: '于万绿成云控平台',
  webTitle: '于万绿成管理平台',
  loadingTitle: '于万绿成',
  webIcon: require('@/assets/images/ywlc/favicon.jpg'),
  loginLogo: require('/public/ywlc_logo.png'),
  menuLogo: require('/public/ywlc_logo.png'),
  slogan: '循环利用  变废为宝'
}

module.exports = project
