import loadingTempalte from './loading.vue'
function loading() {
  return loading
}
loading.install = (Vue) => {
  const Loading = Vue.extend(loadingTempalte)
  const $vm = new Loading()
  document.body.appendChild($vm.$mount().$el)
  Vue.prototype.$loading = {
    show(options) {
      $vm.tips = options.msg
      $vm.show = true
    },
    hide() {
      $vm.show = false
    }
  }
}
export default loading
