export default {
  'confirm': '确认',
  'logout.modal.title': '确认退出',
  'logout.confirm.tip': '请确认是否退出',
  'logout.cancel.btn': '取消',
  'quit.fullscreen': '退出全屏',
  'fullscreen': '全屏模式',
  'menu.hidden': '隐藏菜单',
  'app.setting.pagestyle': '整体风格设置',
  'app.setting.pagestyle.light': '亮色菜单风格',
  'app.setting.pagestyle.dark': '暗色菜单风格',
  'app.setting.pagestyle.realdark': '暗黑模式',
  'app.setting.themecolor': '主题色',
  'app.setting.navigationmode': '导航模式',
  'app.setting.content-width': '内容区域宽度',
  'app.setting.fixedheader': '固定 Header',
  'app.setting.fixedsidebar': '固定侧边栏',
  'app.setting.multitab': '显示页签',
  'app.setting.sidemenu': '侧边菜单布局',
  'app.setting.topmenu': '顶部菜单布局',
  'app.setting.content-width.fixed': 'Fixed',
  'app.setting.content-width.fluid': 'Fluid',
  'app.setting.othersettings': '其他设置',
  'app.setting.weakmode': '色弱模式',
  'app.setting.copy': '拷贝设置',
  'app.setting.loading': '加载主题中',
  'app.setting.copyinfo': '拷贝设置成功 src/config/defaultSettings.js',
  'app.setting.production.hint': '配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件',
  'app.setting.themecolor.daybreak': '环卫绿',
  'app.setting.themecolor.dust': '薄暮',
  'app.setting.themecolor.volcano': '火山',
  'app.setting.themecolor.sunset': '日暮',
  'app.setting.themecolor.cyan': '明青',
  'app.setting.themecolor.green': '极光绿',
  'app.setting.themecolor.geekblue': '极客蓝',
  'app.setting.themecolor.purple': '酱紫',
  'car.add': '添加车辆',
  'car.delete': '删除车辆',
  'car.add.enter.tip': '请输入车辆编号',
  'car.add.enter.password': '请输入车辆密码',
  'car.delete.select.tip': '请选择车辆编号',
  'car.operations.title': '清扫作业车辆分配',
  'car.myvehiclelist.title': '我的车辆列表',
  'car.number': '车辆编号',
  'car.createtime': '建立日期',
  'car.status': '车辆状态',
  'car.power': '车辆电量',
  'car.operator': '操作人员',
  'car.area': '归属区域',
  'car.operation': '操作',
  'car.work.data.title': '工作中车辆',
  'car.charging.data.title': '充电中车辆',
  'car.standby.data.title': '待命中车辆',
  'car.repair.data.title': '维修中车辆',
  'car.undefined.data.title': '未定义车辆',
  'car.select.operator': '选择操作员',
  'car.Offline.btn.text': '下线',
  'car.Online.btn.text': '上线',
  'car.confirm.title.tip': '提示',
  'car.confirm.online.onetext': '确定要对',
  'car.confirm.online.twotext': '车辆进行上线操作吗?',
  'car.confirm.offline.onetext': '确定要对',
  'car.confirm.offline.twotext': '车辆进行下线操作吗?',
  'car.confirm.delete.title.tip': '删除车辆',
  'car.confirm.delete.select.tip': '请选择车辆编号',
  'car.confirm.delete.content.tip': '确定要删除此车辆吗？',
  'car.confirm.add.select.tip': '请选择车辆编号',
  'car.confirm.add.content.tip': '确定要添加此车辆吗？',
  'car.enter.name.tip': '请输入车辆编号',
  'car.new.password': '请输入新密码',
  'car.change.area': '归属区域',
  'car.select.change.area': '请选择归属区域',
  'car.enter.new.password.tip': '请输入新的密码',
  'car.edit.vehicle': '车辆编辑',
  'car.btn.edit.text': '编辑车辆',
  'car.edit.vehicle.password': '修改车辆密码',
  'car.btn.changepassword.text': '修改密码',
  'car.link.monitor.text': '监控',
  cartype: {
    all: '全部车辆'
    // undefined: '未定义车辆',
    // standby: '待命中车辆',
    // work: '工作中车辆',
    // charge: '充电中车辆',
    // repair: '维修中车辆'
  },
  carstatus: {
    // 0: '未定义',
    // 1: '待命中',
    // 2: '作业中',
    // 3: '充电中',
    // 4: '维修中'
    0: '离线',
    1: '在线'
  }
}
