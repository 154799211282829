/*
 * @Auth: linjituan
 * @Date: 2021-09-10 17:18:16
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2024-01-26 10:43:37
 */
import defaultSettings from './defaultSettings'

export default {
  // 皮肤名称、基本信息以及皮肤对应应用的一级域名
  keyMap: {
    light: ['super', 'yw', 'ywlc', 'fleettech', 'suizhou'],
    dark: ['idriver']
  },
  light: {
    color: defaultSettings.primaryColor,
    fileName: 'yw.less'
  },
  dark: {
    color: '#1982ec',
    fileName: 'idriver.less',
    mapTheme: 'ea5a4930f93cbfd98b638770d486f5b0',
    mapStyleJson: [
      {
        featureType: 'background',
        elementType: 'all',
        stylers: {
          color: '#091220ff'
        }
      },
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: {
          color: '#ffffffff'
        }
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: {
          color: '#ffffff00'
        }
      },
      {
        featureType: 'land',
        elementType: 'geometry.fill',
        stylers: {
          color: '#091220ff'
        }
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: {
          color: '#113549ff'
        }
      },
      {
        featureType: 'green',
        elementType: 'geometry.fill',
        stylers: {
          color: '#0e1b30ff'
        }
      },
      {
        featureType: 'manmade',
        elementType: 'all',
        stylers: {
          color: '#12223dff'
        }
      },
      {
        featureType: 'subwaystation',
        elementType: 'all',
        stylers: {
          color: '#113549B2'
        }
      },
      {
        featureType: 'education',
        elementType: 'all',
        stylers: {
          color: '#12223dff'
        }
      },
      {
        featureType: 'medical',
        elementType: 'all',
        stylers: {
          color: '#12223dff'
        }
      },
      {
        featureType: 'scenicspots',
        elementType: 'all',
        stylers: {
          color: '#12223dff'
        }
      },
      {
        featureType: 'building',
        elementType: 'geometry.fill',
        stylers: {
          color: '#113549ff'
        }
      },
      {
        featureType: 'building',
        elementType: 'geometry.stroke',
        stylers: {
          color: '#dadada00'
        }
      },
      {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: {
          color: '#12223dff'
        }
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: {
          color: '#ffffff00'
        }
      }
    ]
  }
}
