/*
 * @Auth: linjituan
 * @Date: 2021-11-22 16:46:53
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-07-27 17:18:26
 */

const project = {
  homeIntroduction: ['园区智慧物流解决方案提供商。', '智能物流协助提升生产效率，无人驾驶参与共创一流重卡。'],
  systemName: '智联智能云控平台',
  webTitle: '智联智能',
  loadingTitle: '智联智能',
  webIcon: require('@/assets/images/fleettech/favicon.png'),
  loginLogo: require('@/assets/images/fleettech/logo.png'),
  menuLogo: require('@/assets/images/fleettech/logo.png'),
  slogan: '驶向环保新未来',
  menuLogoWidth: 192,
  hideRight: true
}

module.exports = project
