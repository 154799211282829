import mqtt from 'mqtt'
import { MQTT_REMOTE_CONTROL_TOPIC } from '@/store/mutation-types'
import { uuid } from '@/utils/util.js'
import { logWithTime } from '@/utils/ruoyi'
// mqtt ACK中result状态值定义
// const AckResult = {
//   0: '成功',
//   1: '未登录或者登录已经失效',
//   2: '已登录',
//   3: 'mapid不存在 or 不匹配当前map',
//   4: 'map version不正确',
//   5: 'RoutingType路径类型不正确',
//   6: '指定的task不存在',
//   7: '任务执行ID错误',
//   8: '预定义全局路径文件不存在',
//   9: '车端不存在地图文件',
//   10: '地图服务器不存在地图文件',
//   92: '存在影响任务运行的HM告警',
//   98: '状态错误',
//   99: '车端内部问题',
//   100: '请求数据错误',
//   101: 'mac地址错误',
//   102: '地图服务器问题',
//   103: '地图使用中',
//   104: '不能同时下载多个地图',
//   110: '生成全局路径失败'
// }
const mqttStore = {
  state: {
    mqttClient: {},
    mqttReady: false,
    mqttClientId: 'mqttjs_',
    // 当前车辆mac地址
    mac: '',
    remoteControlAck: {
      login: undefined,
      cmd: 0
    },
    currentTopics: {
      LOGIN: '',
      LOGIN_ACK: '',
      CMD: '',
      CMD_ACK: '',
      LOGOUT: '',
      LOGOUT_ACK: '',
      AUTOMODE_SWITCH: '',
      AUTOMODE_SWITCH_ACK: '',
      TRAIL_DATA_ACK: ''
    },
    automode: undefined,
    trailData: {
      businessState: {},
      runningState: {},
      location: {}
    }
  },
  mutations: {
    setMqttReady: (state, data) => {
      state.mqttReady = data
    },
    setMqttClient: (state, data) => {
      state.mqttClient = data
    },
    setMqttClientId: (state, data) => {
      console.log('setMqttClientId', data)
      state.mqttClientId = data
    },
    setMac: (state, data) => {
      state.mac = data
    },
    setCurrentTopics: (state, data) => {
      state.currentTopics = data
    },
    setRemoteControlLoginResult: (state, data) => {
      state.remoteControlLoginResult = data
    },
    setRemoteControlCmdResult: (state, data) => {
      state.remoteControlCmdResult = data
    },
    setInitRemoteControlAck: (state, data) => {
      state.remoteControlCmdResult = {
        login: undefined,
        cmd: 0
      }
    },
    setMessage: (state, msg) => {
      const data = msg.data
      const topic = msg.topic
      const ok = data.result === 0 || data.result === 2
      // 处理消息
      if (topic === state.currentTopics.LOGIN_ACK) {
        // 登录Ack
        state.remoteControlAck.login = data.result
        if (ok) {
          state.remoteControlAck.cmd = 0
          logWithTime(`mqtt 登录成功`)
        }
        state.remoteControlAck = { ...state.remoteControlAck }
      } else if (topic === state.currentTopics.CMD_ACK) {
        // 遥控命令Ack
        state.remoteControlAck.cmd = data.result
        state.remoteControlAck = { ...state.remoteControlAck }
      } else if (topic === state.currentTopics.AUTOMODE_SWITCH_ACK) {
        // 切换模式Ack
        if (data.seq === '1') {
          if (ok) {
            // 切自动成功
            state.automode = true
          }
        } else {
          if (ok) {
            // 切手动成功
            state.automode = false
          }
        }
      } else if (topic === state.currentTopics.LOGOUT_ACK) {
        // 退出遥控Ack
        if (ok) {
          state.remoteControlAck.login = undefined
          state.remoteControlAck.cmd = 0
          state.remoteControlAck = { ...state.remoteControlAck }
          if (ok) {
            logWithTime(`mqtt 退出登录成功`)
          }
        }
      } else if (topic === state.currentTopics.TRAIL_DATA_ACK) {
        // 收到车辆实时状态数据
        let businessState = {}
        let runningState = {}
        let location = {}
        const bmState = msg.data.bm_state
        const flmState = data.flm_flm_state
        const locationMsf = data.localization_msf
        if (bmState) {
          businessState = bmState
        }
        if (flmState) {
          runningState = flmState
        }
        if (locationMsf) {
          location = locationMsf
        }
        state.trailData = { businessState, runningState, location }
      }
    }
  },
  actions: {
    // mqtt 连接
    mqttConnect({ commit, state, dispatch }) {
      function connect() {
        const url = process.env.VUE_APP_MQTT_ENDPOINT + '/mqtt'
        const username = process.env.VUE_APP_MQTT_USERNAME
        const password = process.env.VUE_APP_MQTT_PASSWORD
        const clientId = 'web_' + uuid()
        console.log('mqtt url', url)
        console.log('clientId', clientId)
        const params = {
          username: username,
          password: password,
          clientId: clientId,
          keepalive: 90,
          connectTimeout: 3000,
          clean: true
        }
        // console.log('mqtt-connect-params', params)
        try {
          return new Promise((resolve, reject) => {
            const client = mqtt.connect(url, params)
            if (client.on) {
              client.on('connect', () => {
                console.log('mqtt 连接成功')
                commit('setMqttReady', true)
                commit('setMqttClient', client)
                resolve(true)
              })

              client.on('reconnect', function (error) {
                console.log('mqtt 重连', error)
              })

              client.on('error', (e) => {
                // 增加重连机制
                setTimeout(() => {
                  console.log('重新建立连接', e)
                  connect()
                }, 5000)
                reject(e)
              })

              client.on('end', () => {
                console.log('mqtt end')
              })

              client.on('message', (topic, data) => {
                let json = {}
                try {
                  if (Buffer.isBuffer(data)) {
                    json = JSON.parse(data.toString())
                  } else if (typeof data === 'string') {
                    json = JSON.parse(data)
                  } else {
                    json = data
                  }
                  logWithTime(`mqtr topic=${topic}, res=${JSON.stringify(json)}`)
                  // 处理消息
                  commit('setMessage', {
                    topic: topic,
                    data: json
                  })
                } catch (error) {
                  console.log('on message error', error)
                }
              })
            }
          })
        } catch (error) {
          console.log('mqtt.connect error', error)
        }
      }
      return connect()
    },

    // mqtt 发布消息
    // 如：client.publish("mqtt/demo", "hello world!")
    mqttPublish({ state }, { topic, payload }) {
      const { mqttReady, mqttClient } = state
      if (mqttReady) {
        payload.timestamp = new Date().getTime()
        try {
          logWithTime(`mqts send topic=${topic},payload=${JSON.stringify(payload)}`)
          mqttClient.publish(topic, JSON.stringify(payload))
        } catch (e) {
          console.log(`mqtt send error topic=${topic},payload=${JSON.stringify(payload)},error=${JSON.stringify(e)}`)
        }
      }
    },

    // 遥控登录
    mqttRemoteControlLogin($store, { mac, subs }) {
      // 1.遥控登录之前先订阅
      logWithTime(`mqtt 登录遥控，mac=${mac}`)
      $store.commit('setInitRemoteControlAck', '')
      if (subs) {
        $store.commit('setMac', mac)
        const keys = Object.keys(MQTT_REMOTE_CONTROL_TOPIC)
        const topicObj = {}
        keys.forEach((p) => {
          const topic = MQTT_REMOTE_CONTROL_TOPIC[p].replace('%s', mac)
          topicObj[p] = topic
        })
        $store.commit('setCurrentTopics', topicObj)
        mqttStore.actions.mqttRemoteControlSubscribe($store)
      }
      // 2.遥控登录
      setTimeout(() => {
        const topic = $store.state.currentTopics.LOGIN
        const payload = {
          seq: '123'
        }
        mqttStore.actions.mqttPublish($store, { topic, payload })
      }, 500)
    },
    // 切换智驾模式：自动或手动
    mqttRemoteControlAutoModeSwitch($store, { automode }) {
      // 先检查是否已登录
      // 进行切换
      const topic = $store.state.currentTopics.AUTOMODE_SWITCH
      const payload = {
        automode: automode,
        seq: automode ? '1' : '0'
      }
      mqttStore.actions.mqttPublish($store, { topic, payload })
    },

    // 遥控命令
    mqttRemoteControlCmd($store, { payload }) {
      // 先检查是否已登录
      // 进行切换
      const topic = $store.state.currentTopics.CMD
      payload.seq = 'cmd'
      mqttStore.actions.mqttPublish($store, { topic, payload })
    },

    // mqtt 订阅
    mqttSubscribe({ state }, { topic }) {
      logWithTime(`mqtt订阅，topic=${typeof topic === 'string' ? topic : JSON.stringify(topic)}`)
      const { mqttReady, mqttClient } = state
      if (mqttReady) {
        try {
          mqttClient.subscribe(topic, { qos: 0 }, (err) => {
            if (!err) {
              // 订阅成功
              console.log(`mqtt topic 订阅成功！`)
            } else {
              console.log(`mqtt topic 订阅失败！`)
            }
          })
        } catch (e) {
          console.log(`mqtt topic 订阅失败！`)
        }
      }
    },
    // 获取遥控订阅主题列表
    getMqttRemoteControlSubscribeTopics(state) {
      const topicArray = []
      const keys = Object.keys(state.currentTopics)
      keys.forEach((p) => {
        if (p.indexOf('_ACK') >= 0) {
          const topic = state.currentTopics[p]
          topicArray.push(topic)
        }
      })
      return topicArray
    },
    // 遥控订阅相关主题
    mqttRemoteControlSubscribe($store) {
      const topicArray = mqttStore.actions.getMqttRemoteControlSubscribeTopics($store.state)
      mqttStore.actions.mqttSubscribe($store, { topic: topicArray })
    },
    // 遥控退出登录
    mqttRemoteControlLogout($store) {
      logWithTime(`mqtt 遥控退出登录`)
      $store.commit('setInitRemoteControlAck', '')
      // 1.先取消订阅
      mqttStore.actions.mqttRemoteControlUnSubscribe($store)
      // 2.遥控退出登录
      setTimeout(() => {
        const topic = $store.state.currentTopics.LOGOUT
        const payload = {
          seq: '123'
        }
        mqttStore.actions.mqttPublish($store, { topic, payload })
      }, 500)
    },
    // 遥控取消订阅相关主题
    mqttRemoteControlUnSubscribe($store) {
      const topicArray = mqttStore.actions.getMqttRemoteControlSubscribeTopics($store.state)
      mqttStore.actions.mqttUnsubscribe($store, { topic: topicArray })
    },
    // 取消订阅
    mqttUnsubscribe({ state }, { topic }) {
      logWithTime(`mqtt取消订阅，topic=${typeof topic === 'string' ? topic : JSON.stringify(topic)}`)
      const { mqttReady, mqttClient } = state
      if (mqttReady) {
        try {
          mqttClient.unsubscribe(topic, (err) => {
            if (!err) {
              // 取消订阅成功
              console.log(`mqtt topic - 取消订阅成功！`)
            } else {
              console.log(`mqtt topic 取消订阅失败！`)
            }
          })
        } catch (e) {
          console.log(`mqtt topic 取消订阅失败！`)
        }
      }
    },
    // 断开连接
    mqttDisConnect({ state, commit, dispatch }) {
      const { mqttClient, mqttReady } = state
      commit('setMqttReady', false)
      if (mqttReady) {
        console.log('断开mqtt连接')
        mqttClient.end(true)
      }
    },
    // 设置mqtt通讯客户端Id
    setMqttClientId({ commit, state }, id) {
      commit('setMqttClientId', id)
    }
  }
}

export default mqttStore
