/*
 * @LastEditors: wuchangsen
 */
export const ACCESS_TOKEN = 'access_token'

export const SIDEBAR_TYPE = 'sidebar_type'
export const TOGGLE_MOBILE_TYPE = 'is_mobile'
export const TOGGLE_NAV_THEME = 'nav_theme'
export const TOGGLE_LAYOUT = 'layout'
export const TOGGLE_FIXED_HEADER = 'fixed_header'
export const TOGGLE_FIXED_SIDEBAR = 'fixed_sidebar'
export const TOGGLE_CONTENT_WIDTH = 'content_width'
export const TOGGLE_HIDE_HEADER = 'auto_hide_header'
export const TOGGLE_COLOR = 'color'
export const TOGGLE_WEAK = 'weak'
export const TOGGLE_MULTI_TAB = 'multi_tab'
export const APP_LANGUAGE = 'app_language'
export const FROM_URL = 'from_url'
// 遥控Topic
export const MQTT_REMOTE_CONTROL_TOPIC = {
  LOGIN: 'cloud/%s/remotectl/login',
  LOGIN_ACK: 'vehicle/%s/remotectl/login/ack',
  CMD: 'cloud/%s/remotectl/cmd',
  CMD_ACK: 'vehicle/%s/remotectl/cmd/ack',
  LOGOUT: 'cloud/%s/remotectl/logout',
  LOGOUT_ACK: 'vehicle/%s/remotectl/logout/ack',
  AUTOMODE_SWITCH: 'cloud/%s/automodeswitch',
  AUTOMODE_SWITCH_ACK: 'vehicle/%s/automodeswitch/ack',
  TRAIL_DATA_ACK: 'vehicle/%s/trail-data'
}
export const CONTENT_WIDTH_TYPE = {
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}

export const NAV_THEME = {
  LIGHT: 'light',
  DARK: 'dark'
}
