var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["user-layout-wrapper", _vm.isMobile && "mobile"],
      attrs: { id: "userLayout" },
    },
    [
      _c("div", { staticClass: "maskbox" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("router-view", {
              staticStyle: { height: "100%", position: "absolute" },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }