<!--
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2023-05-29 18:29:23
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-06-01 16:57:24
 * @FilePath: \ra-web-admin\src\components\AToolbar\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <vxe-toolbar ref="xToolbar" v-if="reMounted" :custom="custom" :refresh="refreshOpt">
      <div slot="buttons">
        <slot></slot>
      </div>
    </vxe-toolbar>
  </div>
</template>

<script>
export default {
  name: 'AToolbar',
  props: {
    custom: {
      type: [Object, Boolean],
      default: () => ({
        storage: true
      })
    },
    refresh: {
      type: [Object, Boolean],
      default: false
    },
    tid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      refreshOpt: null,
      id: null,
      reMounted: true
    }
  },
  watch: {
    tid(val) {
      if (val) {
        console.log('tid ***', val)
        // this.reMounted = false
        // this.$nextTick(() => {
        //   this.reMounted = true
          this.id = this.$parent._uid + (this.tid || '')
          window.currentToorbarUid[this.tid] = this.$parent._uid
          const path = this.$route.path
          this.tableId = path.replace(/\//g, '-') + '-' + this.tid
          window['vxeTable-' + this.tableId]()
        // })
      }
    }
  },
  created() {
    this.id = this.$parent._uid + (this.tid || '')
    if (!window.currentToorbarUid) {
      window.currentToorbarUid = {}
    }
    window.currentToorbarUid[this.tid] = this.$parent._uid
    if (this.refresh) {
      this.refreshOpt = {
        query: this.refreshQuery
      }
    }
  },
  methods: {
    refreshQuery() {
      this.$emit('refreshQuery')
    }
  }
}
</script>

<style></style>
