<template>
  <div class="clearfix">
    <a-upload
      :action="uploadImgUrl"
      :list-type="isPdf ? 'text' : type == 'image' ? 'picture-card' : 'picture'"
      :show-upload-list="false"
      :headers="headers"
      ref="upload"
      :before-upload="beforeUpload"
      :data="uploadParams"
      @change="handleChange"
    >
      <img v-if="value && type == 'image' && !isPdf" :src="imgValue" alt="avatar" />
      <div v-if="type == 'file'">
        <a-button> <a-icon type="upload" /> 上传 </a-button>
      </div>
      <div v-if="value == '' || value == null">
        <span v-if="type == 'image'">
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">上传</div>
        </span>
      </div>
    </a-upload>
    <span v-if="value && (type == 'file' || isPdf)">
      <!-- <br /> -->
      <a style="margin-right: 10px" :href="imgValue" target="_blank">{{ shortFileName }}</a>
      <a-icon @click="fileDelete" type="delete" />
    </span>
  </div>
</template>

<script>
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { uuid } from '@/utils/util.js'

export default {
  name: 'ImageUpload',
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'image'
    },
    count: {
      type: Number,
      default: 1
    },
    params: {
      type: String,
      default: ''
    },
    // 文件夹
    folder: {
      type: String,
      default: ''
    },
    // 上传类型：上传到阿里云用 aliyunoss
    saveType: {
      type: String,
      default: 'aliyunoss'
    },
    limitPixels: {
      type: Array,
      default: () => []
    },
    uploadImgUri: {
      type: String,
      default: '/common/upload'
    }
  },
  components: {},
  watch: {
    value(newVal, oldVal) {
      if (newVal.indexOf('.pdf') > -1) {
        this.isPdf = true
      } else {
        this.isPdf = false
      }
      if (newVal.indexOf('http://') === -1 && newVal.indexOf('https://') === -1 && this.fileNameHost) {
        this.imgValue = this.fileNameHost + newVal + (this.fileNameParams || '')
      } else {
        this.imgValue = newVal
        this.$emit('input', this.resetImageFileName(newVal))
      }
    }
  },
  data() {
    return {
      loading: false,
      open: false,
      uploadImgUrl: process.env.VUE_APP_BASE_API + this.uploadImgUri,
      headers: {
        Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN)
      },
      pform: this.form,
      uploadParams: this.params,
      isPdf: false,
      shortFileName: '',
      fileList: []
    }
  },
  created() {
    if (this.value) {
      this.imgValue = this.value
      this.$emit('input', this.resetImageFileName(this.value))
    }
  },
  mounted() {
    console.log(this.uploadParams)
    this.setUploadParam()
  },
  methods: {
    handleCancel() {
      this.previewVisible = false
    },
    async handleChange(info) {
      console.log('handleChange', info)
      let fileList = [...info.fileList]

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-2)

      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url
        }
        return file
      })

      this.fileList = fileList
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        if (info.file.response.code !== 200) {
          this.$message.error('上传失败' + info.file.response.msg)
          this.loading = false
          return
        }
        this.loading = false
        let imgUrl = null
        if (info.file.response.url.indexOf('?') !== -1) {
          imgUrl = info.file.response.url + '&v=' + Date.now()
        } else {
          imgUrl = info.file.response.url + '?v=' + Date.now()
        }
        imgUrl = this.resetImageFileName(imgUrl)
        console.log('imgUrl', imgUrl)
        this.$emit('input', info.file.response.fileName)
      }
    },
    beforeUpload(file) {
      // 文件类型(file.type)、大小限制(file.size)
      const isLt2M = file.size / 1024 / 1024 < 2
      console.log('beforeUpload', file)
      if (!isLt2M) {
        this.$message.error('图片大小限制 2MB!')
      }
      return new Promise(async (resolve, reject) => {
        if (this.limitPixels.length) {
          const flag = await this.checkPixedls(file)
          if (!flag) {
            this.$message.error('图片规格限制420*248px等比例!')
            this.$emit('rateErrorBack', file)
            reject(flag)
          }
        }
        isLt2M ? resolve() : reject(isLt2M)
      })
    },
    checkPixedls(file) {
      return new Promise((resolve) => {
        const myimg = URL.createObjectURL(file)
        const img = new Image()
        const that = this
        img.src = myimg
        img.onload = function () {
          console.log('img width:', img.width)
          console.log('img height:', img.height)
          const nowPx = img.width / img.height
          const allowPx = that.limitPixels[0] / that.limitPixels[1]
          if (nowPx.toFixed(2) === allowPx.toFixed(2)) {
            resolve(true)
          } else {
            resolve(false)
          }
        }
      })
    },
    setUploadParam() {
      console.log('setUploadParam')
      if (!this.params && this.saveType === 'aliyunoss') {
        this.uploadParams = {
          fileName: uuid(),
          type: 'aliyunoss',
          folder: this.folder
        }
      }
      console.log(this.uploadParams)
    },
    // 重新设置图片文件名
    resetImageFileName(imageUrl) {
      if (imageUrl) {
        const pictureIndex = imageUrl.indexOf('picture')
        const imgNameEndIndex = imageUrl.indexOf('?') > -1 ? imageUrl.indexOf('?') : imageUrl.length
        const fileName = imageUrl.substring(pictureIndex + 8, imgNameEndIndex)
        console.log('fileName', decodeURIComponent(fileName))
        this.fileNameHost = imageUrl.substring(0, pictureIndex + 8)
        console.log('fileNameHost', this.fileNameHost)

        const arr = fileName.split('/')
        this.shortFileName = arr[arr.length - 1]
        this.fileNameParams = imageUrl.substring(imgNameEndIndex, imageUrl.length)

        return decodeURIComponent(fileName)
      } else {
        this.fileNameHost = ''
        this.shortFileName = ''
        this.fileNameParams = ''
        this.isPdf = false
        return ''
      }
    },
    formatFolder() {},
    fileDelete() {
      this.$emit('input', this.resetImageFileName())
    }
  }
}
</script>
<style lang="less" scoped>
img {
  width: 128px;
  height: 128px;
}
</style>
