<template>
  <vxe-modal v-model="show" width="200" height="150" :showHeader="false" :show-close="false" :show-zoom="false" resize position="center">
    <a-spin :tip="tips" :spinning="true">
      <div class="spin-content"></div>
    </a-spin>
  </vxe-modal>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      tips: ''
    }
  }
}
</script>
<style lang="less" scoped></style>
