/*
 * @Auth: linjituan
 * @Date: 2021-09-07 10:25:02
 * @LastEditors: linjituan
 * @LastEditTime: 2021-11-22 18:30:36
 */

// import { getConfigKey } from '@/api/system/config'
import skinSetting from '@/config/skinSetting'
import store from '@/store/index.js'

const source = window.index_tenant_code
const map = skinSetting.keyMap
let mapKey = ''
for (const key in map) {
  if (map[key].includes(source)) {
    mapKey = key
    break
  }
}

async function initTheme(key) {
  //   const res = await getConfigKey('sys.index.skinName')
  console.log('key', key)
  const themeObj = skinSetting[key]
  const newElement = document.createElement('link')
  newElement.setAttribute('rel', 'stylesheet')
  newElement.setAttribute('href', require(`@/assets/css/custom/${themeObj.fileName}`))
  document.body.appendChild(newElement)
  document.body.setAttribute('class', 'theme-' + key)
  document.getElementById('app').setAttribute('class', 'theme')
  // .theme-dark
  console.log('themeObj', themeObj)
  store.commit('SET_MAP_THEME', themeObj.mapTheme)
  store.commit('SET_MAP_STYLE_JSON', themeObj.mapStyleJson)
}

initTheme(mapKey)
export default initTheme
