/*
 * @Auth: linjituan
 * @Date: 2021-07-21 16:15:21
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-04-19 17:25:08
 */
import client from 'webpack-theme-color-replacer/client'
import generate from '@ant-design/colors/lib/generate'
// import { message } from 'ant-design-vue'

export const themeColor = {
  getAntdSerials(color) {
    // 淡化（即less的tint）
    const lightens = new Array(9).fill().map((t, i) => {
      return client.varyColor.lighten(color, i / 10)
    })
    // colorPalette 变换得到颜色值
    const colorPalettes = generate(color)
    const rgb = client.varyColor.toNum3(color.replace('#', '')).join(',')
    return lightens.concat(colorPalettes).concat(rgb)
  },
  changeColor(newColor) {
    let isDevEnv = false
    const theOrigin = window.location.origin
    if (theOrigin.indexOf('.testiot.revolution.auto') > -1 || theOrigin.indexOf('.iot.revolution.auto') > -1) {
      isDevEnv = false
    } else {
      isDevEnv = true
    }
    if (!isDevEnv) {
      return new Promise((resolve) => {
        resolve()
      })
    }
    const options = {
      newColors: this.getAntdSerials(newColor), // new colors array, one-to-one corresponde with `matchColors`
      changeUrl(cssUrl) {
        return `/${cssUrl}` // while router is not `hash` mode, it needs absolute path
      }
    }
    return client.changer.changeColor(options, Promise)
  }
}

export const updateTheme = (newPrimaryColor) => {
  // const hideMessage = message.loading('正在切换主题', 0)
  themeColor.changeColor(newPrimaryColor).then((r) => {
    // hideMessage()
  })
}

export const updateColorWeak = (colorWeak) => {
  // document.body.className = colorWeak ? 'colorWeak' : '';
  const app = document.body.querySelector('#app')
  colorWeak ? app.classList.add('colorWeak') : app.classList.remove('colorWeak')
}
