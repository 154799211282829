var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-tooltip",
        { attrs: { placement: "bottom" } },
        [
          _c("template", { slot: "title" }, [
            _vm._v(
              " " + _vm._s(_vm.isFullscreen ? "退出全屏" : "切为全屏") + " "
            ),
          ]),
          _c("a-icon", {
            style: _vm.iconStyle,
            attrs: {
              type: _vm.isFullscreen ? "fullscreen-exit" : "fullscreen",
            },
            on: { click: _vm.click },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }