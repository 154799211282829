<!--
 * @Auth: linjituan
 * @Date: 2021-12-20 10:35:20
 * @LastEditors: linjituan
 * @LastEditTime: 2022-01-21 16:34:09
-->
<template>
  <span :id="id" :title="value || title">
    {{ value }}
    <slot />
  </span>
</template>

<script>
export default {
  name: 'ASpan',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    $slots(val) {
      this.setTitle()
    }
  },
  data() {
    return {
      id: '',
      title: this.value
    }
  },
  created() {
    this.setTitle()
  },
  mounted() {
    if (!window.ASpanId) {
      window.ASpanId = 1
    }
    this.id = 'ASpan' + window.ASpanId
    window.ASpanId++
    this.$nextTick(() => {
      this.mutationSpan()
    })
  },
  methods: {
    setTitle() {
      if (this.$slots && this.$slots.default && this.$slots.default.length === 1) {
        this.title = this.$slots.default[0].text
      }
    },
    mutationSpan() {
      // 监听span 节点变化，动态修改title
      var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
      // 选择目标节点
      var target = document.querySelector('#' + this.id)
      // 创建观察者对象
      this.observer = new MutationObserver(mutations => {
        this.setTitle()
      })
      // 配置观察选项:
      var config = { attributes: true, childList: true, characterData: true, subtree: true }
      // 传入目标节点和观察选项
      this.observer.observe(target, config)
    }
  },
  destroyed() {
    this.observer.disconnect()
    this.observer = undefined
  }
}
</script>

<style></style>
