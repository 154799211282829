<template>
  <my-layout
    :menus="menus"
    :collapsed="collapsed"
    :mediaQuery="query"
    :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    v-bind="settings"
  >
    <!-- Ads begin
      广告代码 真实项目中请移除
      production remove this Ads
    -->
    <!-- <ads v-if="isProPreviewSite && !collapsed"/> -->
    <!-- Ads end -->

    <!-- layout content -->
    <!-- 2021.01.15 默认固定页头，去掉样式paddingTop: fixedHeader ? '64' : '0'  -->
    <a-layout-content :style="{ height: '100%', margin: '0 0 0px 0' }">
      <multi-tab v-if="settings.multiTab"></multi-tab>
      <transition name="page-transition"> </transition>
    </a-layout-content>
    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template v-slot:menuHeaderRender>
      <div :style="`width: ${$project.menuLogoWidth || 132}px;`">
        <img v-if="$project.menuLogo" :src="$project.menuLogo" class="logo loayoutMenuLogo" alt="logo" />
        <!-- <div class="logo-text" v-if="!collapsed">
          <div class="logo-text-header">EVOLUTION</div>
          <div class="logo-text-body">{{ title }}</div>
        </div> -->
        <!-- <h1>{{ title }}</h1> -->
      </div>
    </template>

    <template v-slot:menuAvatarRender>
      <avatar-menu :current-user="currentUser" :collapsed="!collapsed" />
    </template>

    <setting-drawer v-if="isDevEnv" :settings="settings" @change="handleSettingChange" />
    <template v-slot:rightContentRender>
      <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
    </template>
    <template v-slot:footerRender>
      <global-footer />
    </template>
    <router-view />
  </my-layout>
</template>

<script>
import MultiTab from '@/components/MultiTab'
import { SettingDrawer, updateTheme } from '@/components/MyLayout'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'
import AvatarMenu from '@/components/Avatar/AvatarMenu'

import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'
// import Ads from '@/components/Other/CarbonAds'

export default {
  name: 'BasicLayout',
  components: {
    AvatarMenu,
    SettingDrawer,
    RightContent,
    GlobalFooter,
    // Ads,
    MultiTab
  },
  data() {
    return {
      isDevEnv: false, // process.env.NODE_ENV === 'development',
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW && process.env.NODE_ENV !== 'development',
      // end
      // multiTab: defaultSettings.multiTab,
      fixedHeader: defaultSettings.fixedHeader,
      // base
      menus: [],
      // 侧栏展开状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        multiTab: defaultSettings.multiTab,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
      currentUser: {}
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters
    })
  },
  created() {
    const routes = this.mainMenu.find((item) => item.path === '/')
    this.menus = (routes && routes.children) || []
    // 处理侧栏展开状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
    this.checkEnvByOrigin()
  },
  mounted() {
    console.log(' **8 ***', this.$project.menuLogo)
    if (!this.$project.menuLogo) {
      document.getElementById('logo').style.display = 'none'
    }
    setTimeout(() => {
      this.currentUser = {
        name: 'Ravo'
      }
    }, 1500)
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }

    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      console.log('updateTheme 初始化主题颜色')
      updateTheme(this.settings.primaryColor)
    }
  },
  methods: {
    i18nRender,
    checkEnvByOrigin() {
      console.log('origin', window.location.origin)
      const theOrigin = window.location.origin
      if (theOrigin.indexOf('.testiot.revolution.auto') > -1 || theOrigin.indexOf('.iot.revolution.auto') > -1) {
        this.isDevEnv = false
      } else {
        this.isDevEnv = true
      }
    },
    handleMediaQuery(val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse(val) {
      this.collapsed = val
    },
    handleSettingChange({ type, value }) {
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    }
  }
}
</script>

<style lang="less">
@import './BasicLayout.less';
.loayoutMenuLogo {
  width: 100% !important;
  height: 100% !important;
  .logo-text {
    width: 100px;
    height: 32px;
    float: right;
    color: #00989e;
    .logo-text-header {
      height: 10px;
      font-weight: 700;
      text-align: right;
      padding-right: 14px;
    }
    .logo-text-body {
      margin-top: 10px;
      font-weight: 500;
      text-align: right;
      padding-right: 14px;
    }
  }
}
.ant-layout-sider-children {
  .ant-menu {
    .ant-menu-item-selected > a {
      color: #ffffff;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #013a07;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #013a07;
  }
}
</style>
