/*
 * @Auth: linjituan
 * @Date: 2021-07-22 10:10:16
 * @LastEditors: linjituan
 * @LastEditTime: 2022-06-09 20:13:07
 */
// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import permission from './directive/permission'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
// import ProLayout, { PageHeaderWrapper } from '@/components/ProLayout'
import MyLayout, { PageHeaderWrapper } from '@/components/MyLayout'
import FooterToolBar from '@/components/FooterToolbar'
import FileUpload from '@/components/FileUpload'
import themePluginConfig from '../config/themePluginConfig'

// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import config from '@/config/defaultSettings'
import { getDicts } from '@/api/system/dict/data'
import { getConfigKey } from '@/api/system/config'
import { getTenantCode } from '@/utils/util'
import { parseTime, parseDateTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, download, handleTree, downloadFile } from '@/utils/ruoyi'
import Print from 'vue-print-nb'

// 换肤功能导入
import './utils/skin'
import vcolorpicker from 'vcolorpicker'
import loading from './components/loading/loading.js'
Vue.use(vcolorpicker)
Vue.use(loading)
// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.parseDateTime = parseDateTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.downloadFile = downloadFile
Vue.prototype.handleTree = handleTree
Vue.prototype.getTenantCode = getTenantCode
Vue.prototype.tableSize = config.tableSize
Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
// Vue.component('pro-layout', ProLayout)
// use my-layout components
Vue.component('my-layout', MyLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
Vue.component('footer-tool-bar', FooterToolBar)
Vue.component('file-upload', FileUpload)

Vue.use(permission)
Vue.use(Print)

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
} else {
  Vue.config.devtools = false
}

// eslint-disable-next-line camelcase
window.umi_plugin_ant_themeVar = themePluginConfig.theme
new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: (h) => h(App)
}).$mount('#app')
