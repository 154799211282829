var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.reMounted
        ? _c(
            "vxe-toolbar",
            {
              ref: "xToolbar",
              attrs: { custom: _vm.custom, refresh: _vm.refreshOpt },
            },
            [
              _c(
                "div",
                { attrs: { slot: "buttons" }, slot: "buttons" },
                [_vm._t("default")],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }