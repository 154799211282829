import Vue from 'vue'

// base library
import {
  Layout,
  Input,
  InputNumber,
  Button,
  Switch,
  Radio,
  Checkbox,
  Select,
  Card,
  Form,
  Row,
  Col,
  Modal,
  // Table,
  Icon,
  Avatar,
  Spin,
  Menu,
  Drawer,
  Tooltip,
  Alert,
  Tag,
  Divider,
  DatePicker,
  TimePicker,
  Upload,
  Pagination,
  FormModel,
  Descriptions,
  message,
  notification,
  Space
} from 'ant-design-vue'

// ext library
import BaiduMap from 'vue-baidu-map'
import 'xe-utils'
import VXETable from 'vxe-table'
// import { VXETable, Modal as VXEModal } from 'vxe-table'
import 'vxe-table/lib/style.css'
import VueCropper from 'vue-cropper'
import Dialog from '@/components/Dialog'
import MultiTab from '@/components/MultiTab'
import PageLoading from '@/components/PageLoading'
import PermissionHelper from '@/core/permission/permission'
import ASpan from '@/components/ASpan/index'
import AToolbar from '@/components/AToolbar/index'
import './directives/action'
import RaTable from '@/components/RaTable/ra-table'

// 图片预览组件 Added By JamsonWu @2021-09-06
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

const baiduMapAK = process.env.VUE_APP_BAIDU_MAP_AK

// Vue.use(VXETable).use(VXEModal)
Vue.use(VXETable)
Vue.use(Layout)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use('ATextarea', Input.Textarea)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Card)
Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
// Vue.use(Table)
Vue.use(Icon)
Vue.use(Avatar)
Vue.use(Spin)
Vue.use(Menu)
Vue.use(Drawer)
Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(DatePicker.MonthPicker.name, DatePicker.MonthPicker)
Vue.use(Upload)
Vue.use(Pagination)
Vue.use(FormModel)
Vue.use(Descriptions)
Vue.use(Space)
Vue.use(() => {
  Vue.component(ASpan.name, ASpan)
  Vue.component(AToolbar.name, AToolbar)
  Vue.component('ATable', RaTable)
})
Vue.use(BaiduMap, {
  ak: baiduMapAK
})

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning

// 信息提示方法扩展
// 成功提示
Vue.prototype.$successEx = (content, onOk) => {
  Modal.success({
    title: '提示',
    content: content,
    zIndex: 99999,
    onOk: () => {
      onOk && onOk()
    }
  })
}

// 警告提示
Vue.prototype.$warningEx = (content, onOk) => {
  Modal.warning({
    title: '提示',
    content: content,
    zIndex: 99999,
    onOk: () => {
      onOk && onOk()
    }
  })
}

// 错误提示
Vue.prototype.$errorEx = (content, onOk) => {
  Modal.error({
    title: '提示',
    content: content,
    zIndex: 99999,
    onOk: () => {
      onOk && onOk()
    }
  })
}

Vue.use(Dialog) // this.$dialog func
Vue.use(MultiTab)
Vue.use(PageLoading)
Vue.use(PermissionHelper)
Vue.use(VueCropper)
process.env.NODE_ENV !== 'production' && console.warn('[antd-pro] NOTICE: Antd use lazy-load.')

// 按需引入
Vue.use(Viewer)
