import request from '@/utils/request'

// 查询调用开放接口的客户端token管理列表
export function listClientToken (query) {
  return request({
    url: '/openapi/clientToken/list',
    method: 'get',
    params: query
  })
}

// 查询调用开放接口的客户端token管理详细
export function getClientToken (id) {
  return request({
    url: '/openapi/clientToken/' + id,
    method: 'get'
  })
}

// 新增调用开放接口的客户端token管理
export function addClientToken (data) {
  return request({
    url: '/openapi/clientToken',
    method: 'post',
    data: data
  })
}

// 修改调用开放接口的客户端token管理
export function updateClientToken (data) {
  return request({
    url: '/openapi/clientToken',
    method: 'put',
    data: data
  })
}

// 删除调用开放接口的客户端token管理
export function delClientToken (id) {
  return request({
    url: '/openapi/clientToken/' + id,
    method: 'delete'
  })
}

// 导出调用开放接口的客户端token管理
export function exportClientToken (query) {
  return request({
    url: '/openapi/clientToken/export',
    method: 'get',
    params: query
  })
}

// 验证token
export function authToken (query) {
  return request({
    url: '/openapi/token/auth',
    method: 'get',
    params: query
  })
}
