<template>
  <div>
    <a-divider />
    <a-menu class="ant-pro-drop-down menu" style="height: auto" :default-selected-keys="['1']" :open-keys.sync="openKeys" mode="inline" @click="handleClick" :inline-collapsed="collapsed">
      <a-sub-menu key="sub2" @titleClick="titleClick">
        <span slot="title" class="ant-pro-account-avatar">
          <a-avatar size="small" :src="avatar" class="antd-pro-global-header-index-avatar" />
          <span v-if="collapsed" style="padding-left: 40px; color: rgba(0, 0, 0, 0.45)">{{ nickname }}</span>
        </span>
        <a-menu-item key="center" @click="handleToCenter">
          <a href="javascript:;">
            <a-icon type="user" />
            个人中心
          </a>
        </a-menu-item>
        <a-menu-item key="settings" @click="handleToSettings">
          <a href="javascript:;">
            <a-icon type="setting" />
            个人设置
          </a>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item key="logout" @click="handleLogout">
          <a href="javascript:;">
            <a-icon type="logout" />
            退出登录
          </a>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AvatarDropdown',
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openKeys: ['sub1']
    }
  },
  watch: {
    openKeys(val) {
      console.log('openKeys', val)
    }
  },
  computed: {
    ...mapGetters(['avatar', 'nickname'])
  },
  methods: {
    handleClick(e) {
      console.log('click', e)
    },
    titleClick(e) {
      console.log('titleClick', e)
    },
    handleToCenter() {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout(e) {
      Modal.confirm({
        title: '提示',
        content: '确定注销并退出系统吗？',
        zIndex: 1000000,
        onOk: () => {
          return this.$store.dispatch('Logout').then(() => {
            location.href = '/index'
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
</style>
