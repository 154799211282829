/* eslint-disable */
export const printANSI = () => {
  // console.clear()
  console.log('[Revolution Web] created()')
  // ASCII - ANSI Shadow
  let text = `

  _____                 _       _   _                __          __  _     
 |  __ \\               | |     | | (_)               \\ \\        / / | |    
 | |__) |_____   _____ | |_   _| |_ _  ___  _ __ _____\\ \\  /\\  / /__| |__  
 |  _  // _ \\ \\ / / _ \\| | | | | __| |/ _ \\| '_ \\______\\ \\/  \\/ / _ \\ '_ \\ 
 | | \\ \\  __/\\ V / (_) | | |_| | |_| | (_) | | | |      \\  /\\  /  __/ |_) |
 |_|  \\_\\___| \\_/ \\___/|_|\\__,_|\\__|_|\\___/|_| |_|       \\/  \\/ \\___|_.__/ 
                                                                           
                                                                           

`
  console.log(`%c${text}`, 'color: #fc4d50')
}
