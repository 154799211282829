/*
 * @Auth: linjituan
 * @Date: 2021-11-22 16:46:53
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-05-29 15:33:11
 */

const project = {
  homeIntroduction: ['智行者智驾管理平台。', '自动驾驶技术驶向新未来。'],
  systemName: '智行者云控平台',
  webTitle: '智行者智驾管理平台',
  loadingTitle: '智行者清扫',
  webIcon: require('@/assets/images/idriver/favicon.png'),
  loginLogo: require('@/assets/images/idriver/logo4.png'),
  menuLogo: require('@/assets/images/idriver/logo4.png'),
  slogan: '驶向新未来'
}

module.exports = project
