/**
 * Custom icon list
 * All icons are loaded here for easy management
 * @see https://vue.ant.design/components/icon/#Custom-Font-Icon
 *
 * 自定义图标加载表
 * 所有图标均从这里加载，方便管理
 */

import bugIcon from '@/assets/icons/bug.svg?inline'
import buildIcon from '@/assets/icons/build.svg?inline'
import buttonIcon from '@/assets/icons/button.svg?inline'
import bxAnalyseIcon from '@/assets/icons/bxAnalyse.svg?inline'
import cascaderIcon from '@/assets/icons/cascader.svg?inline'
import chartIcon from '@/assets/icons/chart.svg?inline'
import checkboxIcon from '@/assets/icons/checkbox.svg?inline'
import clipboardIcon from '@/assets/icons/clipboard.svg?inline'
import codeIcon from '@/assets/icons/code.svg?inline'
import colorIcon from '@/assets/icons/color.svg?inline'
import componentIcon from '@/assets/icons/component.svg?inline'
import dashboardIcon from '@/assets/icons/dashboard.svg?inline'
import dateRangeIcon from '@/assets/icons/dateRange.svg?inline'
import dateIcon from '@/assets/icons/date.svg?inline'
import dictIcon from '@/assets/icons/dict.svg?inline'
import documentationIcon from '@/assets/icons/documentation.svg?inline'
import downloadIcon from '@/assets/icons/download.svg?inline'
import dragIcon from '@/assets/icons/drag.svg?inline'
import druidIcon from '@/assets/icons/druid.svg?inline'
import editIcon from '@/assets/icons/edit.svg?inline'
import eyeOpenIcon from '@/assets/icons/eyeOpen.svg?inline'
import githubIcon from '@/assets/icons/github.svg?inline'
import guideIcon from '@/assets/icons/guide.svg?inline'
import inputIcon from '@/assets/icons/input.svg?inline'
import jobIcon from '@/assets/icons/job.svg?inline'
import logIcon from '@/assets/icons/log.svg?inline'
import logininforIcon from '@/assets/icons/logininfor.svg?inline'
import messageIcon from '@/assets/icons/message.svg?inline'
import monitorIcon from '@/assets/icons/monitor.svg?inline'
import numberIcon from '@/assets/icons/number.svg?inline'
import onlineIcon from '@/assets/icons/online.svg?inline'
import passwordIcon from '@/assets/icons/password.svg?inline'
import pdfIcon from '@/assets/icons/pdf.svg?inline'
import peoplesIcon from '@/assets/icons/peoples.svg?inline'
import phoneIcon from '@/assets/icons/phone.svg?inline'
import postIcon from '@/assets/icons/post.svg?inline'
import questionIcon from '@/assets/icons/question.svg?inline'
import radioIcon from '@/assets/icons/radio.svg?inline'
import rateIcon from '@/assets/icons/rate.svg?inline'
import redisIcon from '@/assets/icons/redis.svg?inline'
import rowIcon from '@/assets/icons/row.svg?inline'
import selectIcon from '@/assets/icons/select.svg?inline'
import serverIcon from '@/assets/icons/server.svg?inline'
import swaggerIcon from '@/assets/icons/swagger.svg?inline'
import switchIcon from '@/assets/icons/switch.svg?inline'
import systemIcon from '@/assets/icons/system.svg?inline'
import textareaIcon from '@/assets/icons/textarea.svg?inline'
import timeRangeIcon from '@/assets/icons/timeRange.svg?inline'
import timeIcon from '@/assets/icons/time.svg?inline'
import toolIcon from '@/assets/icons/tool.svg?inline'
import treeTableIcon from '@/assets/icons/treeTable.svg?inline'
import treeIcon from '@/assets/icons/tree.svg?inline'
import uploadIcon from '@/assets/icons/upload.svg?inline'
import userIcon from '@/assets/icons/user.svg?inline'
import validCodeIcon from '@/assets/icons/validCode.svg?inline'
import arrowsRightIcon from '@/assets/icons/arrows-right.svg?inline'
import artificialIcon from '@/assets/icons/artificial.svg?inline'
import avatarIcon from '@/assets/icons/avatar.svg?inline'
import cameraIcon from '@/assets/icons/camera.svg?inline'
import carIcon from '@/assets/icons/car.svg?inline'
import daohangIcon from '@/assets/icons/daohang.svg?inline'
import deleteIcon from '@/assets/icons/delete.svg?inline'
import dingweiIcon from '@/assets/icons/dingwei.svg?inline'
import dingweizhuangtaiIcon from '@/assets/icons/dingweizhuangtai.svg?inline'
import dipanVerticalIcon from '@/assets/icons/dipan-vertical.svg?inline'
import dipanIcon from '@/assets/icons/dipan.svg?inline'
import fullScreenIcon from '@/assets/icons/full-screen.svg?inline'
import healthIcon from '@/assets/icons/health.svg?inline'
import infoCircleIcon from '@/assets/icons/info-circle.svg?inline'
import jiaoduIcon from '@/assets/icons/jiaodu.svg?inline'
import jiasuduIcon from '@/assets/icons/jiasudu.svg?inline'
import jingweiduIcon from '@/assets/icons/jingweidu.svg?inline'
import locationIcon from '@/assets/icons/location.svg?inline'
import mapIcon from '@/assets/icons/map.svg?inline'
import moonIcon from '@/assets/icons/moon.svg?inline'
import nothingIcon from '@/assets/icons/nothing.svg?inline'
import pointLoseIcon from '@/assets/icons/point-lose.svg?inline'
import quanxianIcon from '@/assets/icons/quanxian.svg?inline'
import quitFullScreenIcon from '@/assets/icons/quit-full-screen.svg?inline'
import roleSettingIcon from '@/assets/icons/role-setting.svg?inline'
import ronghedingweiIcon from '@/assets/icons/ronghedingwei.svg?inline'
import suduIcon from '@/assets/icons/sudu.svg?inline'
import sunIcon from '@/assets/icons/sun.svg?inline'
import taskListIcon from '@/assets/icons/task-list.svg?inline'
import warningIcon from '@/assets/icons/warning.svg?inline'
import wastebinIcon from '@/assets/icons/wastebin.svg?inline'
import weixingIcon from '@/assets/icons/weixing.svg?inline'
import yibiaopanIcon from '@/assets/icons/yibiaopan.svg?inline'
import yuandianIcon from '@/assets/icons/yuandian.svg?inline'
import zuobiaoIcon from '@/assets/icons/zuobiao.svg?inline'
import deviceIcon from '@/assets/icons/device.svg?inline'
import vehicleAutoIcon from '@/assets/icons/vehicle-auto.svg?inline'

const allIcon = {
  bugIcon,
  buildIcon,
  buttonIcon,
  bxAnalyseIcon,
  cascaderIcon,
  chartIcon,
  checkboxIcon,
  clipboardIcon,
  codeIcon,
  colorIcon,
  componentIcon,
  dashboardIcon,
  dateRangeIcon,
  dateIcon,
  dictIcon,
  documentationIcon,
  downloadIcon,
  dragIcon,
  druidIcon,
  editIcon,
  eyeOpenIcon,
  githubIcon,
  guideIcon,
  inputIcon,
  jobIcon,
  logIcon,
  logininforIcon,
  messageIcon,
  monitorIcon,
  numberIcon,
  onlineIcon,
  passwordIcon,
  pdfIcon,
  peoplesIcon,
  phoneIcon,
  postIcon,
  questionIcon,
  radioIcon,
  rateIcon,
  redisIcon,
  rowIcon,
  selectIcon,
  serverIcon,
  swaggerIcon,
  switchIcon,
  systemIcon,
  textareaIcon,
  timeRangeIcon,
  timeIcon,
  toolIcon,
  treeTableIcon,
  treeIcon,
  uploadIcon,
  userIcon,
  validCodeIcon,
  arrowsRightIcon,
  artificialIcon,
  avatarIcon,
  cameraIcon,
  carIcon,
  daohangIcon,
  deleteIcon,
  dingweiIcon,
  dingweizhuangtaiIcon,
  dipanVerticalIcon,
  dipanIcon,
  fullScreenIcon,
  healthIcon,
  infoCircleIcon,
  jiaoduIcon,
  jiasuduIcon,
  jingweiduIcon,
  locationIcon,
  mapIcon,
  moonIcon,
  nothingIcon,
  pointLoseIcon,
  quanxianIcon,
  quitFullScreenIcon,
  roleSettingIcon,
  ronghedingweiIcon,
  suduIcon,
  sunIcon,
  taskListIcon,
  warningIcon,
  wastebinIcon,
  weixingIcon,
  yibiaopanIcon,
  yuandianIcon,
  zuobiaoIcon,
  deviceIcon,
  vehicleAutoIcon
}

export default allIcon
