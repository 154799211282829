/*
 * @Date: 2021-07-21 16:15:21
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-20 14:42:48
 */
import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
import inputFilter from './inputFilter'

moment.locale('zh-cn')

Vue.filter('NumberFormat', function(value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('inputNumber', function(val) {
  const reg = new RegExp('^-?([1-9]\\d*|[1-9]\\d*\\.\\d*)?$')
  if (!reg.test(val)) {
    return val.replace(/\w/g, '')
  }
})

Vue.use(inputFilter)
