var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-divider"),
      _c(
        "a-menu",
        {
          staticClass: "ant-pro-drop-down menu",
          staticStyle: { height: "auto" },
          attrs: {
            "default-selected-keys": ["1"],
            "open-keys": _vm.openKeys,
            mode: "inline",
            "inline-collapsed": _vm.collapsed,
          },
          on: {
            "update:openKeys": function ($event) {
              _vm.openKeys = $event
            },
            "update:open-keys": function ($event) {
              _vm.openKeys = $event
            },
            click: _vm.handleClick,
          },
        },
        [
          _c(
            "a-sub-menu",
            { key: "sub2", on: { titleClick: _vm.titleClick } },
            [
              _c(
                "span",
                {
                  staticClass: "ant-pro-account-avatar",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("a-avatar", {
                    staticClass: "antd-pro-global-header-index-avatar",
                    attrs: { size: "small", src: _vm.avatar },
                  }),
                  _vm.collapsed
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            "padding-left": "40px",
                            color: "rgba(0, 0, 0, 0.45)",
                          },
                        },
                        [_vm._v(_vm._s(_vm.nickname))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "center", on: { click: _vm.handleToCenter } },
                [
                  _c(
                    "a",
                    { attrs: { href: "javascript:;" } },
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _vm._v(" 个人中心 "),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "a-menu-item",
                { key: "settings", on: { click: _vm.handleToSettings } },
                [
                  _c(
                    "a",
                    { attrs: { href: "javascript:;" } },
                    [
                      _c("a-icon", { attrs: { type: "setting" } }),
                      _vm._v(" 个人设置 "),
                    ],
                    1
                  ),
                ]
              ),
              _c("a-menu-divider"),
              _c(
                "a-menu-item",
                { key: "logout", on: { click: _vm.handleLogout } },
                [
                  _c(
                    "a",
                    { attrs: { href: "javascript:;" } },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _vm._v(" 退出登录 "),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }