/*
 * @Auth: linjituan
 * @Date: 2021-11-22 17:25:02
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2024-01-26 10:42:38
 */
/*
 * @Auth: linjituan
 * @Date: 2021-11-22 17:05:10
 * @LastEditors: linjituan
 * @LastEditTime: 2021-11-22 17:05:11
 */
import Vue from 'vue'

const projectMapKye = ['yw', 'idriver', 'ywlc', 'fleettech', 'suizhou']
let source = window.index_tenant_code
if (!projectMapKye.includes(source)) {
  source = 'yw'
}
const project = require(`@/config/project/${source}`)

Vue.prototype.$project = project

export default project
