var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("global-footer", {
    staticClass: "footer custom-render",
    scopedSlots: _vm._u([
      {
        key: "links",
        fn: function () {
          return [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.revolution.auto",
                  target: "_blank",
                },
              },
              [_vm._v("Revolution")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "copyright",
        fn: function () {
          return [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.revolution.auto",
                  target: "_blank",
                },
              },
              [_vm._v("Revolution")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }