var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrpCls },
    [
      _c(
        "a-space",
        { attrs: { size: "middle" } },
        [
          _c("screenfull"),
          _c("avatar-dropdown", {
            class: _vm.prefixCls,
            attrs: { menu: _vm.showMenu, "current-user": _vm.currentUser },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }