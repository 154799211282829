<template>
  <div>
    <a-tooltip placement="bottom">
      <template slot="title">
        {{ isFullscreen ? '退出全屏' : '切为全屏' }}
      </template>
      <a-icon :type="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="click" :style="iconStyle" />
    </a-tooltip>
  </div>
</template>

<script>
import screenfull from 'screenfull'

export default {
  name: 'Screenfull',
  props: {
    targetElementId: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconStyle() {
      const style = { fontSize: '20px' }
      if (this.iconColor) {
        style.color = this.iconColor
      }
      return style
    }
  },
  data() {
    return {
      isFullscreen: false
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.destroy()
  },
  methods: {
    click() {
      if (!screenfull.isEnabled) {
        this.$message.warning('你的浏览器不支持全屏')
        return false
      }
      if (this.targetElementId) {
        const el = document.getElementById(this.targetElementId)
        screenfull.toggle(el)
      } else {
        screenfull.toggle()
      }
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
      this.$emit('isFullscreen', this.isFullscreen)
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change)
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change)
      }
    }
  }
}
</script>
