import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { logWithTime } from '@/utils/ruoyi'

const websocketCmd = {
  state: {
    websocketCmdObj: {},
    websocketCmdReady: false,
    websocketCmdId: 0,
    sendString: ''
  },
  mutations: {
    setWebsocketCmdReady: (state, data) => {
      state.websocketCmdReady = data
    },
    setWebsocketCmdObj: (state, data) => {
      state.websocketCmdObj = data
    },
    addWebsocketCmdId: (state) => {
      state.websocketCmdId += 1
    },
    setSendString: (state, data) => {
      state.sendString = data
    }
  },
  actions: {
    // 连接
    websocketCmdConnect({ commit, state, dispatch }) {
      const url = window.location.origin.replace('http', 'ws')
      const token = storage.get(ACCESS_TOKEN)
      let reconnect = false

      function connection() {
        let timer = null
        let interval = null
        if (!state.websocketCmdReady) {
          return new Promise((resolve, reject) => {
            logWithTime('正在连接 websocket_cmd')
            const server = new WebSocket(url + '/ws?access_token=' + token)
            server.onopen = () => {
              logWithTime('websocketCmd 连接成功')
              commit('setWebsocketCmdReady', true)
              commit('setWebsocketCmdObj', server)
              if (reconnect) {
                reconnect = false
              }
              interval = setInterval(() => {
                console.log('websocketCmd 心跳')
                logWithTime('websocketCmd 心跳')
                server.send(
                  '{"id":' + state.websocketCmdId + ',"jsonrpc":"2.0","method":"ping","params":{"ping":"testping"}}'
                )
              }, 30000)
              resolve(server)
            }
            server.onclose = (event) => {
              commit('setWebsocketCmdReady', false)
              commit('setWebsocketCmdObj', {})
              reconnect = true
              // 连接错误，5s重新连接一次
              timer && clearTimeout(timer)
              interval && clearTimeout(interval)
              timer = setTimeout(() => {
                return connection()
              }, 5000)
              logWithTime(`websowebsocketCmd 连接关闭,5秒后重连,error=${JSON.stringify(event)}`)
            }
            server.onerror = (event) => {
              commit('setWebsocketCmdReady', false)
              commit('setWebsocketCmdObj', {})
              reconnect = true
              // 连接错误，5s重新连接一次
              timer && clearTimeout(timer)
              interval && clearTimeout(interval)
              timer = setTimeout(() => {
                return connection()
              }, 5000)
              logWithTime(`websocketCmd 连接错误,5秒后重连,error=${JSON.stringify(event)}`)
              reject(event)
            }
          })
        }
      }
      return connection()
    },
    // 发送
    websocketCmdSend({ dispatch, commit, state }, { id = '', cmd = '', str }) {
      if (!id || !cmd) return
      const sendStr =
        str ||
        `{"id": ${
          state.websocketCmdId
        },"jsonrpc":"2.0","method":"vehicleNewControl","params":{"vehicleId":${JSON.stringify(
          id
        )}, "cmd":${JSON.stringify(cmd)}}}`
      if (state.websocketCmdReady) {
        commit('addWebsocketCmdId')
        // logWithTime(`websocket_cmd=${sendStr}`)
        state.websocketCmdObj.send(sendStr)
        state.websocketCmdObj.onmessage = (event) => {
          logWithTime(`收到数据Cmd-` + event.data)
        }
      } else {
        dispatch('websocketCmdConnect').then(() => {
          commit('addWebsocketCmdId')
          state.websocketCmdObj.send(sendStr)
          state.websocketCmdObj.onmessage = (event) => {
            logWithTime(`收到数据Cmd-` + event.data)
          }
        })
      }
    }
  }
}
export default websocketCmd
