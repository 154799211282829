/*
 * @Date: 2021-07-23 15:36:06
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-20 11:38:06
 */
import Vue from 'vue'
/**
 *  实现功能
 *  1、默认情况下只禁止空格输入
 *  2、限制只能输入整数
 *  3、限制只能输入整数和小数（number类）
 *  4、限制只能输入手机号
 *  5、限制最大值和最小值(抛出错误给回调函数)
 */
const addListener = function(el, type, fn) {
  el.addEventListener(type, fn, false)
}
const spaceFilter = function(el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/\s+/, '')
  })
}
const intFilter = function(el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/\D/g, '')
  })
  addListener(el, 'blur', () => {
    el.value = el.value.replace(/\D/g, '')
  })
}
const numberFilter = function(el) {
  let oldVal = ''
  console.log('numberFilter', el)
  function filterHandler() {
    const reg = new RegExp('^-?([1-9]\\d*|[1-9]\\d*\\.\\d*)?$')
    if (reg.test(el.value) || !el.value) {
      oldVal = isNaN(el.value) ? '' : el.value
    } else {
      el.value = oldVal
    }
  }
  addListener(el, 'keyup', filterHandler)
  addListener(el, 'blur', filterHandler)
}
const specialFilter = function(el) {
  addListener(el, 'keyup', () => {
    el.value = el.value.replace(/[^a-zA-Z0-9\u4E00-\u9FA5]/g, '')
  })
}
const phoneFilter = function(el) {
  addListener(el, 'blur', () => {
    if (!el.value) {
      return
    }
    const phoneReg = new RegExp('^(13|14|15|16|17|18|19)[0-9]{9}$')
    if (!phoneReg.test(el.value)) {
      alert('手机号输入错误')
      el.value = ''
    }
  })
}
const urlFilter = function(el) {
  addListener(el, 'blur', () => {
    if (!el.value) {
      return
    }
    const urlReg = /(^#)|(^http(s*):\/\/[^\s]+\.[^\s]+)/
    if (!urlReg.test(el.value)) {
      alert('url输入错误')
      el.value = ''
    }
  })
}
const inputFilter = {
  bind(el, binding) {
    if (el.tagName.toLowerCase() !== 'input') {
      el = el.getElementsByTagName('input')[0]
    }
    spaceFilter(el)
    switch (binding.arg) {
      case 'int':
        intFilter(el)
        break
      case 'number':
        numberFilter(el)
        break
      case 'special':
        specialFilter(el)
        break
      case 'phone':
        phoneFilter(el)
        break
      case 'url':
        urlFilter(el)
        break
      default:
        break
    }
  }
}

const install = function(Vue) {
  Vue.directive('inputFilter', inputFilter)
}

if (window.Vue) {
  window.inputFilter = inputFilter
  Vue.use(install)
}

inputFilter.install = install

export default inputFilter
