/*
 * @Auth: linjituan
 * @Date: 2022-08-05 14:39:20
 * @LastEditors: linjituan
 * @LastEditTime: 2022-08-05 16:04:08
 */
export const PropTypes = {
  array: {
    type: Array,
    default: () => []
  },
  bool: {
    type: Boolean,
    default: false
  },
  string: {
    type: String,
    default: ''
  },
  object: {
    type: Object,
    default: () => {}
  },
  func: {
    type: Function,
    default: () => () => {}
  },
  any: {
    type: null
  },
  number: {
    type: Number,
    default: 0
  }
}

export const initDefaultProps = (propTypes, defaultProps = {}) => {
  Object.keys(defaultProps).forEach((k) => {
    if (propTypes[k]) {
      propTypes[k].default && (propTypes[k].default = defaultProps[k])
    } else {
      throw new Error(`not have ${k} prop`)
    }
  })
  console.log('propTypes', propTypes)
  return propTypes
}
