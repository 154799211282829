var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clearfix" },
    [
      _c(
        "a-upload",
        {
          ref: "upload",
          attrs: {
            action: _vm.uploadImgUrl,
            "list-type": _vm.isPdf
              ? "text"
              : _vm.type == "image"
              ? "picture-card"
              : "picture",
            "show-upload-list": false,
            headers: _vm.headers,
            "before-upload": _vm.beforeUpload,
            data: _vm.uploadParams,
          },
          on: { change: _vm.handleChange },
        },
        [
          _vm.value && _vm.type == "image" && !_vm.isPdf
            ? _c("img", { attrs: { src: _vm.imgValue, alt: "avatar" } })
            : _vm._e(),
          _vm.type == "file"
            ? _c(
                "div",
                [
                  _c(
                    "a-button",
                    [
                      _c("a-icon", { attrs: { type: "upload" } }),
                      _vm._v(" 上传 "),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.value == "" || _vm.value == null
            ? _c("div", [
                _vm.type == "image"
                  ? _c(
                      "span",
                      [
                        _c("a-icon", {
                          attrs: { type: _vm.loading ? "loading" : "plus" },
                        }),
                        _c("div", { staticClass: "ant-upload-text" }, [
                          _vm._v("上传"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
      _vm.value && (_vm.type == "file" || _vm.isPdf)
        ? _c(
            "span",
            [
              _c(
                "a",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { href: _vm.imgValue, target: "_blank" },
                },
                [_vm._v(_vm._s(_vm.shortFileName))]
              ),
              _c("a-icon", {
                attrs: { type: "delete" },
                on: { click: _vm.fileDelete },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }