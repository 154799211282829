/*
 * @LastEditors: wuchangsen
 */
import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import websocket from './modules/websocket'
import websocketCmd from './modules/websocket_cmd'
import mqttStore from './modules/mqttStore'

// default router permission control
// import permission from './modules/permission'
import permission from './modules/async-router'

// dynamic router permission control (Experimental)
// import permission from './modules/async-router'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    websocket,
    websocketCmd,
    permission,
    mqttStore
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters
})
