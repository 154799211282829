<!--
 * @Auth: linjituan
 * @Date: 2021-07-21 16:15:21
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-20 17:44:21
-->
<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import { ConfigProvider } from 'ant-design-vue'

export default {
  data() {
    return {}
  },
  components: {
    AConfigProvider: ConfigProvider
  },
  computed: {
    locale() {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && setDocumentTitle(`${i18nRender(title)} - ${domTitle}`)

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  },
  mounted() {
    const docKeydown = event => {
      if (!this.isInPage(document.activeElement)) {
        return
      }
      var e = event || window.event
      if (e && e.keyCode === 13) {
        // 回车键的键值为13
        document.getElementById('tableQueryBtn') && document.getElementById('tableQueryBtn').click() // 调用查询按钮
      }
    }
    document.addEventListener('keydown', docKeydown, false)
    this.setWebIcon()
  },
  methods: {
    isInPage(node) {
      return node === document.body ? true : document.getElementById('app').contains(node)
    },
    setWebIcon() {
      var link = document.querySelector('link[rel*="icon"]')
      link.type = 'image/x-icon'
      link.rel = 'icon'
      link.href = this.$project.webIcon
    }
  }
}
</script>
<style lang="less" scoped>
@import url('//at.alicdn.com/t/font_2783203_6j7hqfwcrv9.css');
</style>
