import { parseDateTime } from '@/utils/ruoyi'
// 数据格式
//  本次登录唯一ID
// seq: {
//    前端发送时间
//   PageSendTime: new Date().getTime(),
//    车端ack回发时间
//   AckReturnTime: new Date().getTime(),
//    前端收到ack回发时间
//   PageReceiveTime: new Date().getTime()
// }
const delayStatis = {
  lastCmdTime: new Date().getTime(),
  delayTime: [],
  delayTimeInfo: [],
  ackReturnTime: [],
  // 登录
  login: {},
  // 登出
  logout: {},
  // 模式切换
  automode: {},
  // 遥控命令
  cmd: {},
  // 保存指有指令
  cacheArray: [],
  cmdTimesInSecond: {}
}
// 遥控开始记录
export function AddStartRecord(type, timestamp, seq) {
  const statisItem = delayStatis[type]
  if (!statisItem) {
    return
  }
  if (type === 'cmd') {
    const curTime = new Date().getTime()
    delayStatis.lastCmdTime = curTime
    const second = parseDateTime(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss')
    if (delayStatis.cmdTimesInSecond[second]) {
      delayStatis.cmdTimesInSecond[second] += 1
    } else {
      delayStatis.cmdTimesInSecond[second] = 1
    }
  }

  // statisItem[seq] = {}
  // statisItem[seq].PageSendTime = timestamp
  // 使用数组保证有序保存
  delayStatis.cacheArray.push({
    type: type,
    seq: seq,
    PageSendTime: timestamp
  })
}

// 遥控收到ack记录
export function AddEndRecord(type, timestamp, seq) {
  // 对象是无序的
  // const statisItem = delayStatis[type]
  // if (!statisItem) {
  //   return
  // }
  // const record = statisItem[seq]
  // if (!record) {
  //   return
  // }
  // record.AckReturnTime = timestamp
  // record.PageReceiveTime = new Date().getTime()
  // record.TotalDelayTime = record.PageReceiveTime - record.PageSendTime
  // record.ReturnDelayTime = record.PageReceiveTime - record.AckReturnTime
  // record.PageSendTimeFormat = parseDateTime(new Date(record.PageSendTime), 'yyyy-MM-dd HH:mm:ss:SSS')
  // record.AckReturnTimeFormat = parseDateTime(new Date(record.AckReturnTime), 'yyyy-MM-dd HH:mm:ss:SSS')
  // record.PageReceiveTimeFormat = parseDateTime(new Date(record.PageReceiveTime), 'yyyy-MM-dd HH:mm:ss:SSS')

  // 改为使用时生成
  // delayStatis.delayTime.push(record.TotalDelayTime)
  // delayStatis.ackReturnTime.push(record.AckReturnTime)
  // 使用数组保证有序性
  delayStatis.cacheArray.map((record) => {
    if (record.seq === seq) {
      record.AckReturnTime = timestamp
      record.PageReceiveTime = new Date().getTime()
      record.TotalDelayTime = record.PageReceiveTime - record.PageSendTime
      record.ReturnDelayTime = record.PageReceiveTime - record.AckReturnTime
      record.PageSendTimeFormat = parseDateTime(new Date(record.PageSendTime), 'yyyy-MM-dd HH:mm:ss:SSS')
      record.AckReturnTimeFormat = parseDateTime(new Date(record.AckReturnTime), 'yyyy-MM-dd HH:mm:ss:SSS')
      record.PageReceiveTimeFormat = parseDateTime(new Date(record.PageReceiveTime), 'yyyy-MM-dd HH:mm:ss:SSS')
      delayStatis.delayTime.push({
        value: record.TotalDelayTime,
        name: `${record.seq}=>${record.PageReceiveTimeFormat} - ${record.PageSendTimeFormat}=${record.TotalDelayTime}`
      })
      delayStatis.ackReturnTime.push(record.AckReturnTime)
      return record
    }
  })
}
// 初始化延迟统计对象
export function InitDelayStatis() {
  delayStatis.login = {}
  delayStatis.logout = {}
  delayStatis.automode = {}
  delayStatis.cmd = {}
  delayStatis.delayTime = []
  delayStatis.cmdTimesInSecond = {}
  delayStatis.ackReturnTime = []
  delayStatis.cacheArray = []
  delayStatis.delayTimeInfo = []
}
// 导出遥控延迟日志
export function LogDelayStatis() {
  // const count = delayStatis.delayTime.length
  // const tmax = Math.max.apply(null, delayStatis.delayTime)
  // const tmin = Math.min.apply(null, delayStatis.delayTime)
  // let sum = 0
  // delayStatis.delayTime.forEach((p) => (sum += p))
  // const avg = Math.floor(sum / count)
  // delayStatis.totalCount = count
  // delayStatis.maxDelayTime = tmax
  // delayStatis.minDelayTime = tmin
  // delayStatis.avgDelayTime = avg
  // console.log('遥控延迟统计数据', JSON.stringify(delayStatis))
  // delayStatis.delayTime = delayStatis.cacheArray.map((p) => p.TotalDelayTime)
  // delayStatis.ackReturnTime = delayStatis.cacheArray.map((p) => p.AckReturnTime)
  return delayStatis
}

// 获取时间戳及遥控命令的seq
export function getTimestampAndSeq(vehicleId) {
  const random = parseInt(Math.random() * 100000)
  const timestamp = new Date().getTime()
  return {
    timestamp: timestamp,
    seq: vehicleId + '' + timestamp + '' + random
  }
}
