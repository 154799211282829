import storage from 'store'
import { login, getInfo, logout } from '@/api/login'
import { authToken } from '@/api/openapi/clientToken'
import { ACCESS_TOKEN, FROM_URL } from '@/store/mutation-types'

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    sessionUserId: 0,
    roles: [],
    info: {},
    tenant: {},
    mapTheme: '',
    mapStyleJson: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_USER_ID: (state, userId) => {
      state.sessionUserId = userId
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_TENANT: (state, tenant) => {
      // 如果租户没有坐标，则设置公司默认坐标：上海市浦东区于万公司
      const defaultPos = [121.55858, 31.230833]
      let val = {}
      if (tenant) {
        val = tenant
      }
      if (!val.lng) {
        val.lng = defaultPos[0]
      }
      if (!val.lat) {
        val.lat = defaultPos[1]
      }
      state.tenant = val
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_MAP_THEME: (state, theme) => {
      state.mapTheme = theme
    },
    SET_MAP_STYLE_JSON: (state, styleJson) => {
      state.mapStyleJson = styleJson
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then(res => {
            storage.set(ACCESS_TOKEN, res.token, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', res.token)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    AuthToken({ commit }, data) {
      return new Promise((resolve, reject) => {
        authToken({ token: data.token })
          .then(res => {
            const expireTime = 7 * 24 * 60 * 60 * 1000
            storage.set(ACCESS_TOKEN, res.token, expireTime)
            storage.set(FROM_URL, data.callback, 0)
            commit('SET_TOKEN', res.token)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token)
          .then(res => {
            const user = res.user
            const avatar =
              user.avatar === ''
                // ? require('@/assets/images/4.png')
                ? require('@/assets/icons/avatar.svg')
                : user.avatar.indexOf('http://') > -1 || user.avatar.indexOf('https://') > -1
                ? user.avatar + '?v=' + new Date().getTime()
                : process.env.VUE_APP_BASE_API + user.avatar
            if (res.roles && res.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', res.roles)
              commit('SET_PERMISSIONS', res.permissions)
            } else {
              commit('SET_ROLES', ['ROLE_DEFAULT'])
            }
            commit('SET_USER_ID', user.userId)
            commit('SET_NAME', user.nickName)
            commit('SET_AVATAR', avatar)
            commit('SET_INFO', user)
            commit('SET_TENANT', res.tenant)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', [])
            storage.remove(ACCESS_TOKEN)
          })
      })
    }
  }
}

export default user
