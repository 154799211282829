/*
 * @Auth: linjituan
 * @Date: 2021-11-22 16:46:53
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-05-29 15:45:23
 */

const project = {
  homeIntroduction: [
    '环境卫生智能化整体解决方案提供商。',
    '用智能美化环境是我们的职责所在，无人驾驶技术带来环卫运营服务的革命新未来。'
  ],
  systemName: '于万智驾云控平台',
  webTitle: '于万智驾',
  loadingTitle: '于万智驾',
  webIcon: require('@/assets/images/favicon.png'),
  loginLogo: require('@/assets/images/logo2.png'),
  menuLogo: require('@/assets/images/logo2.png'),
  slogan: '驶向环保新未来'
}

module.exports = project
