var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      attrs: {
        width: "200",
        height: "150",
        showHeader: false,
        "show-close": false,
        "show-zoom": false,
        resize: "",
        position: "center",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("a-spin", { attrs: { tip: _vm.tips, spinning: true } }, [
        _c("div", { staticClass: "spin-content" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }